@import "../../styles/variables";

.apartmentGroup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 40px;
  align-items: flex-end;
}

.delim {
  color: $blue;
  padding-bottom: 5px;
  margin-top: 50px;
  margin-bottom: 20px;
  border-bottom: 1px solid $blue;
  width: 100%;
}

.offerSubGroup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 30px;
  align-items: end;
  width: 100%;
  margin-top: 30px;
}

.offerGroup {
  border: 2px dashed $placeholderColor;
  padding: 20px;
  width: 96%;
  margin-top: 30px;
}