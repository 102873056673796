@import "../../../styles/variables";

.root {
  height: 23px;
  font-size: 12px;
  //min-width: 158px;
  //width: 158px;
  display: flex;
}

.input {
  height: 100%;
  padding: 0 0 0 5px;
  border: 1px solid $primaryBlue;
  border-right: none;
  border-radius: 4px 0 0 4px;
  width: calc(100% - 30px);

  &::placeholder{
    font-size: 10px;
  }
}

.inputClearOff {
  width: 100%;
  border: 1px solid $primaryBlue;
  border-radius: 4px;
}

.clear {
  width: 30px;
  height: 100%;
  border: 1px solid $primaryBlue;
  border-left: none;
  cursor: pointer;
  border-radius: 0 4px 4px 0;

  display: flex;
  align-items: center;
  justify-content: center;
}


.withSelect {
  border-left: none;
  border-radius: 0px 4px 4px 0px !important;
}

.withSelectActive {
  border-left: none;
  border-radius: 0px !important;
}
