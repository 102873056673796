

.root {
  display: flex;
  width: 100vw;
  height: 100vh;
  background-image: url("../../images/background.png");
  background-color: #8697CB;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: cover;
  justify-content: center;
  align-items: center;
}

.form {
  width: 400px;
  height: 350px;
  background: white;
  border-radius: 5px;
}

.name {
  width: 100%;
  text-align: center;
  padding: 15px 0 10px 0;
  border-bottom: 2px solid #EEEEFF;
}