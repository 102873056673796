@import "../../styles/variables";

.col1 {
  width: 1%
}

.col2 {
  width: 1%
}

.col3 {
  width: 10%
}

.col4 {
  width: 20%
}

.col5 {
  width: 20%
}

.col6 {
  width: 10%
}

.col7 {
  width: 10%
}

.col8 {
  width: 10%
}

.col9 {
  width: 10%
}

.col10 {
  width: 10%
}

.col11 {
  width: 10%
}

.col12 {
  width: 10%
}

.table {
  display: table;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-collapse: collapse;
  empty-cells: show;
}
.tableCaption {
  display: table-caption;
  padding: 10px;
  color: #333;
  font-size: 16px;
  text-align: center;
}
.tableColgroup {
  display: table-column-group;
}
.tableCol {
  display: table-column;
}
.tableThead {
  display: table-header-group;
}
.tableTbody {
  display: table-row-group;
}
.tableTfoot {
  display: table-footer-group;
}
.tableTr {
  display: table-row;
}
.tableTh {
  display: table-cell;
  padding: 10px;
  font-weight: bold;
  text-align: left;
}
.tableTd {
  display: table-cell;
  padding: 10px;
}

.showAll {
  height: 100%;
  display: flex;
  align-items: end;
  justify-content: center;
  color: $blue;
  cursor: pointer;
  font-size: 16px;
}

.root {
  height: 100%;
  width: 100%;
  background: white;
}

.clientTableBody {
  background: white;
  height: calc(100% - 100px);
  width: 100%;
  position: relative;
}

.scrollTest {
  @include scrollStyle;
}

.pairedColumn {

}

.header {
  height: 70px;
  background: white;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}

.headerLeft {
  display: flex;
  grid-gap: 10px;
  justify-content: space-between;
  align-items: center;
  padding-left: 35px;
  padding-right: 50px;
  width: 100%;
  height: 100%;
}

.headerRight {
  display: flex;
  grid-gap: 10px;
}


.row {
  display: flex;
  align-items: center;
  border-top: 1px solid #F5F6F8;
  padding: 0 10px;
  grid-gap: 10px;
  height: 100%;
}

.activeRow {

}

.menuIcon {
  color: $textColorSecondary;
}

.rowMenu {
  display: flex;
  justify-content: center;
  align-items: center;
}


.rowActive {
  background: $bgBlue;
}

.rowEven {
  background-color: #f2f2f2 !important;
}

.rowAdded.rowEven {
  background-color: #109CF1 !important;
}

.cellBorder {
  //border-left: 1px solid black;
  //border-right: 1px solid black;
}


@keyframes blinkingText{
  0%		{ background: $bgBlue;}
  25%		{ background: #2ea9f3;}
  50%		{ background: #21a5f5;}
  75%		{ background: #179ff1;}
  100%	{ background: $blue;}
}

.rowAdded {
  background: $blue !important;
  animation: blinkingText 4s ease-in;


  color: white;

  & .comment {
    color: white;
  }

  & .status {
    color: white;
  }

  & .menuIcon {
    color: white;
  }
}



.rowItem {
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}

.column {

}

.status {
  color: $textColorSecondary;
  min-width: 145px;
}

.inWork {
  color: $orange;
}

.files {

}

.number {
  min-width: 160px;
  width: 160px;
}

.numberDeal {

}

.ellipsisHoverName {
  min-width: 160px;
  width: 160px;
  position: relative;
  overflow: hidden;
  white-space: nowrap;

  &:hover {
    text-overflow: unset !important;
    overflow: unset !important;
    color: transparent;
    & > div {
      display: block !important;
      color: white;
      background-color: black;
    }
  }
}

.overflowEllipsis {
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.clientNameLabel {
  background: white;
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  color: $primaryPurple;
}

.clientName {
  min-width: 153px;
  width: 153px;
}

.clientOPF {
  min-width: 135px;
  width: 135px;
}

.bank {

}

.insuranceObjectTypeId {
}

.ss {

}


.sp {

}


.sellerName {
  min-width: 130px;
  width: 130px;
}

.date {
  min-width: 155px;
  width: 155px;
}

.contractNumber {
  min-width: 150px;
  width: 150px;
}

.quotationStatus {
  min-width: 160px;
  width: 160px;
}


input:focus, textarea:focus, select:focus{
  outline: none;
}

.nameSearch {
  border: 1px solid $primaryBlue;
  border-radius: 4px;
  height: 23px;
  font-size: 12px;
  min-width: 158px;
  padding: 0 0 0 5px;
}

.dateInput {
  /*
  min-width: 120px;
  width: 120px;
  */

  & > div > input {
    padding: 0 0 0 5px !important;
    height: 25px;
    font-size: 12px;
  }


 /* & > div > div > button {
    padding: 0 1px 0 0;
  }

  & > div > div > button > svg {
    width: 18px;
  }*/
}


.spinnerContainer {
  position: absolute;
  z-index: 20;
  left: calc(50% - 10px);
  bottom: 10px;
}


.sortMirrorVertical {
  transform: scale(1, -1);
}

.sortValue {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 5px;
}