@import "../../../styles/variables";


.root {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.clientForm {
  margin: 0 auto;
  background: white;
  width: 50%;
  height: 400px;
  padding: 20px;
  //overflow-y: auto;
}

.header {
  font-weight: 500;
  margin-bottom: 40px;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
}

.btnContainer {
  width: 100%;
  height: 65%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.payButton {
  width: 100px;
  background-color: #2ea9f3;
  color: #fff;
}

.payButton:hover {
  background-color: #5ac8fa;
}

.rowItem {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  width: 100px;
}

.row {
  display: flex;
  align-items: center;
  border-top: 1px solid #616161;
  padding: 10px 0;
  grid-gap: 10px;
  height: 100%;
  text-align: center;
}

.row:first-child {
  border-top: none;
}