@import "../../styles/variables";

.root {
  height: 100vh;
  width: 100%;
  background: white;
}


.contactGroup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 30px;
  align-items: start;
  margin-top: 20px;
  //margin-top: 5px;
  border: 2px dashed $placeholderColor;
  padding: 20px;
  width: calc(100% - 40px);
}

.mainGroup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 30px;
  align-items: end;
  padding-bottom: 50px;
}

.requisitesGroup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 30px;
  align-items: end;
  border: 2px dashed $placeholderColor;
  width: 96%;
  padding: 20px;
  margin-top: 30px;
}

.position {
  margin-top: 24.2px;
}

.phoneGroup {
  //margin-bottom: 15px;
}