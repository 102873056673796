@import "../../styles/variables";

.root {
  height: 100%;
  width: 100%;
  background: white;
  overflow-y: auto;
  margin-left: 3px;
  @include scrollStyle;
}

.showAll {
  height: 40px;
  display: flex;
  align-items: end;
  justify-content: center;
  color: $blue;
  cursor: pointer;
  font-size: 16px;
}

.loadingContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chooseClient {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: $textColor;
  text-align: center;
}

.inputWrapper {
  padding-left: 3px;
}

.searchInput {
  width: 100%;

  & > div::before {
    border-bottom: 2px solid $bgBlue;
  }

  & > div > input {
    padding: 10px 10px;
    background: white;

    &::placeholder {
      font-size: 14px;
    }
  }
}