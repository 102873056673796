@import "../../../styles/variables";

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: fit-content;
  color: #3DD598;
  cursor: pointer;
}