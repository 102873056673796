@import "../../styles/variables";

.root {
  background: $mainBlue;
  width: 100%;
  position: relative;
}

.loader {
  position: absolute;
  top: 10px;
  left: 165px;
  z-index: 100000;
}

.header {
  height: 40px;
  background: white;
  border-left: 3px solid $mainBlue;
  display: flex;
  align-items: center;
  font-size: 10px;
  line-height: normal;
  color: $textColor;
}

.timeWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 100%;
}

.time {
  font-size: 12px;
  font-weight: bold;

}


.delim {
  width: 2px;
  height: 70%;
  border-radius: 2px;
  background: $mainBlue;
}


.queryWrapper {
  padding-left: 5px;
}

.buttons {
  display: flex;
  grid-gap: 5px;
}

.button {
  background: $primaryPurple;
  color: white;
  height: 18px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 3px;
  line-height: normal;
}

.body {
  width: 100%;
  margin-left: 3px;
  padding: 0 15px;
  max-height: 100%;
  overflow-y: scroll;
}

.toolBar {
  display: flex;
  background: white;
  position: absolute;
  height: fit-content;
  width: 100%;
  left: 0;
  bottom: 100px;
  min-height: 40px;
  padding: 5px 0 10px 0;
}

.submit {
  background: none;
  border: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

.sendIcon {
  color: $blue;
}

.textarea {
  width: 85%;

  @include scrollStyle;
}

.form {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.list {
  transform: scale(-1, 1);
  @include scrollStyle;
}