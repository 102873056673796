@import "../../../styles/variables";


.root {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.clientForm {
  margin: 0 auto;
  background: white;
  width: 50%;
  height: 400px;
  padding: 20px;
  //overflow-y: auto;
}

.header {
  font-weight: 400;
  margin-bottom: 40px;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
}

.btnContainer {
  width: 100%;
  height: 65%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit {
  width: 300px;
}