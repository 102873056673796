@import "../../styles/variables";

.root {
  height: 500px;
  width: 30%;
  background: white;
  padding: 40px;
}

.header {
  margin-bottom: 40px;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
}

.content {
  overflow-y: auto;
  height: 80%;
  @include scrollStyle;
}

.submitContainer {
  width: 100%;
  display: flex;
  justify-content: end;
}

.item {
  border-bottom: 1px solid $placeholderColor;
  margin-bottom: 30px;
  display: flex;
}

.itemName {
  width: 150px;
}

.fileName {
  display: flex;
  align-items: center;
  grid-gap: 20px;
}

.fileItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  border-bottom: 1px solid $placeholderColor;
}

.fileHeader {
  margin: 50px 0 20px 0;
  color: $blue;
  border-bottom: 1px solid $blue;
}

.applied {
  color: $primaryGreen;
  font-size: 20px;
}

.notApplied {
  color: $textColorSecondary;
  font-size: 20px;
}

.rejectBtn {
  background-color: #e39393;
  color: black;
}