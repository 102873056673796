@import "../../styles/variables";


.root {
  height: 100%;
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  border-radius: 5px;
}

.error {
  color: $red;
}


.submit {
  margin-top: 30px !important;
  width: 200px;
  height: 42px;
}

.submitError {
  margin-top: 0px !important;
}

.label {
  color: #90A0B7;
  font-size: 12px;
}

.input {
  width: 300px;
}