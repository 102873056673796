$rightSplitPaneWidth: 350px;

.root {
  height: calc(100vh - 60px - 40px);
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.leftSplitPane {
  width: calc(100% - (#{$rightSplitPaneWidth}));
  position: relative;
}

.rightSplitPane {
  width: $rightSplitPaneWidth;
  position: relative;
}