@import "../../styles/variables";

.apartmentGroupFirst {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 40px;
  align-items: baseline;
}

.apartmentGroup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 40px;
  align-items: flex-end;
}

.mb2 {
  margin-bottom: 20px;
}

.fullWidthGroup {
  width: 200% !important;
}

.builtYear {
  margin-bottom: 20px;
}

.delim {
  color: $blue;
  padding-bottom: 5px;
  margin-top: 50px;
  margin-bottom: 20px;
  border-bottom: 1px solid $blue;
  width: 100%;
}

.info {
  color: $orange;
  padding-bottom: 5px;
  margin-top: 50px;
  margin-bottom: 20px;
  border-bottom: 1px solid $orange;
  width: 100%;
}


.weight {
  font-weight: 700;
}

.fire_resistance_system {
  margin-bottom: 20px;
}


.groupCheckBox {
  margin: 25px 0;
  font-weight: bold;
}

.offerSubGroup {
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 30px;
  align-items: end;
  width: 100%;
  margin-top: 30px;
}

.offerGroup {
  border: 2px dashed $placeholderColor;
  padding: 20px;
  width: 96%;
  margin-top: 30px;
}

.deputyGroup {
  padding: 20px;
  width: 96%;
  margin-top: 30px;
}