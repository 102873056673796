$bgBlue: #F3F3FF;
$mainBlue: #E8E8FF;
$blue: #109CF1;
$primaryBlue: #C6D9FF;
$lightBlue: #e3edff;
$primaryGreen: #3DD598;
$textColor: #707683;
$textColorSecondary: #323C47;
$placeholderColor: #90A0B7;
$lightGray: #F1F1F5;
$primaryPurple: #8F8FD4;
$lightGray: #F1F1F5;
$orange: #FF974A;
$red: #FC5A5A;
$linkColor: #334D6E;

@mixin scrollStyle {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    width: 8px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primaryBlue;
    width: 6px;
    border-radius: 4px;
  }
}