@import "../../../styles/variables";


.root {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.clientForm {
  width: 70%;
  height: 80%;
  //overflow-y: auto;
}