@import "../../styles/variables";

$errorRed: #d32f2f;


.fileError {
  border: 2px solid $red !important;
  color: $red !important;
}

.root {
  height: 80vh;
  width: 100%;
  background: white;
}

.checkboxWrapperError {
  color: $errorRed;
}

.deleteBtn {
  color: rgba(255, 0, 20, 0.6);
  display: flex;
  grid-column-gap: 5px;
  align-items: center;
  justify-content: flex-end;
  line-height: normal;
  cursor: pointer;
  margin-top: 10px;
  font-size: 10px;


  &:hover {
    & + div {
      background: rgba(255, 0, 0, 0.1);
      //border: 2px dashed red;
    }
  }

  & > svg {
    width: 16px;
  }

}

.fileContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.deleteFileIcon {
  color: $red;
  cursor: pointer;
}

.deleteBtn {

}

.checkBoxError {
  margin-left: 10px;
}

.submit {
  margin-top: 30px !important;
  width: 200px;
  height: 42px;
}

.label {
  color: #90A0B7;
  font-size: 12px;
}

.input {
  width: 100%;
}

.checkBox {

}

.root {
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
}
.title {
  padding-left: 15px;
  font-weight: 700;
}


.group {
/*  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  align-items: end;*/
}

.groupAddBtn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.groupAddBtnDisabled {
  opacity: 0.6;
  cursor: none;
  pointer-events: none;
}

.noTabFormRoot {
  height: 80%;
  overflow-y: auto;
  padding: 0 10px;
  @include scrollStyle;
}

.tabsContent {
  padding: 0px;
  height: 70%;

  & > div > div {
    overflow-y: auto;
    height: 97%;
    @include scrollStyle;
  }
}

.tabPanel {
  width: 100%;
}

.submitBtnContainer {
  width: 100%;
  display: flex;
  justify-content: end;
}

.submitBtnContainerDisabled {
  pointer-events: none;
}