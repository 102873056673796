@import "../../styles/variables";

.header {
  width: 100%;
  height: 40px;
  background: white;
  display: flex;
  justify-content: space-between;
}

.logo {
  width: 85px;
  min-width: 85px;
  height: 100%;
  background: $bgBlue;
  color: $blue;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.menuEntry {
  height: 100%;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  line-height: normal;
  text-decoration: none;
  color: #334D6E;
  font-weight: 400;
  cursor: pointer;
}

.menuEntryIcon {
  padding-right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menuEntryIcon > svg {
  width: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.searchInput {
  height: calc(100% - 4px);
  width: 100%;
  padding-left: 10px;
  border: none;

  &::placeholder {
    color: $placeholderColor;
  }
}

.searchIconWrapper {
  padding-left: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editMode {
  display: flex;
  white-space: nowrap;
  width: 130%;
  height: 100%;
  align-items: center;
}

.searchIcon {
  color: $placeholderColor;
}

.linkWrapper {
  display: flex;
  grid-gap: 20px;
  margin-right: 40px;
}

.chatIcon {
  width: 17px !important;
}

.accountInfo {
  display: flex;
  align-items: center;
  margin-right: 30px;
  line-height: normal;
  cursor: pointer;
  position: relative;

  &:hover {

    & .accountName {
      text-overflow: unset;
      max-width: unset;
    }

    & > .accountMenu {
      display: block;
    }

    & > .accountData > .accountName {
      color: $blue;
    }

    & > .accountData > .accountEmail {
      color: $blue;
    }
  }
}

.accountNonIcon {
  color: $primaryBlue;
  width: 30px !important;
  height: 30px !important;
}

.accountData {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}

.accountName {
  color: $textColorSecondary;
  font-size: 11px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 130px;

}

.accountEmail {
  color: $textColor;
  font-size: 9px;
}

.accountMenu {
  display: none;
  position: absolute;
  z-index: 500;
  left: 0px;
  top: 40px;

  background: white;
}

.menuItem {
  display: flex;
  justify-content: space-between;
  color: $textColorSecondary;
  align-items: center;
  height: 30px;
  padding: 10px 20px;
  font-size: 12px;
  border-bottom: 2px solid $bgBlue;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background: $bgBlue;
    color: $blue;

    & > .menuIcon {
      color: $blue;
    }
  }
}

.menuIcon {
  color: $textColor;
  width: 15px;
}

.notifications {
  height: 100%;
  min-width: 40px;
  display: flex;
  align-items: center;
  margin-right: 25px;
  justify-content: center;
  position: relative;

  &:hover {
    cursor: pointer;
    & > .notificationIcon {
      color: $blue
    }
  }
}

.notificationIcon {
  color: $placeholderColor;
  height: 25px;
  width: 25px;
}

.notificationCount {
  position: absolute;
  background: $orange;
  left: 18px;
  top: 10px;
  font-size: 8px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  line-height: 20px
}

.headerItemsWrapper {
  display: flex;
}