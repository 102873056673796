@import "../../styles/variables";


.showAll {
  height: 100%;
  display: flex;
  align-items: end;
  justify-content: center;
  color: $blue;
  cursor: pointer;
  font-size: 16px;
}

.root {
  height: 100%;
  width: 100%;
  background: white;
  overflow-x: auto;
}

.clientTableBody {
  background: white;
  height: calc(100% - 100px);
  width: 100%;
  position: relative;
}

.scrollTest {
  @include scrollStyle;
}



.header {
  height: 40px;
  background: white;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}

.headerLeft {
  display: flex;
  grid-gap: 10px;
  justify-content: space-between;
  padding-left: 35px;
  padding-right: 50px;
  width: 100%;
}

.headerRight {
  display: flex;
  grid-gap: 10px;
}


.row {
  display: flex;
  align-items: center;
  border-top: 1px solid #F5F6F8;
  padding: 0 10px;
  grid-gap: 10px;
  height: 100%;
}

.activeRow {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  padding-right: 240px;
}

.menuIcon {
  width: 21px;
  color: $textColorSecondary;
}

.rowMenu {
  display: flex;
  justify-content: center;
  align-items: center;
}


.rowActive {
  background: $bgBlue;
}


@keyframes blinkingText{
  0%		{ background: $bgBlue;}
  25%		{ background: #2ea9f3;}
  50%		{ background: #21a5f5;}
  75%		{ background: #179ff1;}
  100%	{ background: $blue;}
}

.rowAdded {
  background: $blue;
  animation: blinkingText 4s ease-in;


  color: white;

  & .comment {
    color: white;
  }

  & .status {
    color: white;
  }

  & .menuIcon {
    color: white;
  }
}



.rowItem {
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}

.column {

}

.status {
  color: $textColorSecondary;
  min-width: 145px;
}

.inWork {
  color: $orange;
}

.name {
  min-width: 160px;
  width: 160px;
}

.inn {
  min-width: 103px;
  width: 103px;
}

.type {
  min-width: 115px;
  width: 115px;
}

.comment {
  min-width: 135px;
  width: 135px;
  color: $textColor;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;

}

.date {
  min-width: 120px;
  width: 120px;
}


input:focus, textarea:focus, select:focus{
  outline: none;
}

.nameSearch {
  border: 1px solid $primaryBlue;
  border-radius: 4px;
  height: 23px;
  font-size: 12px;
  min-width: 158px;
  padding: 0 0 0 5px;
}

.dateInput {
  min-width: 120px;
  width: 120px;

  & > div > input {
    padding: 0 0 0 5px !important;
    height: 25px;
    font-size: 12px;
  }

  & > div > div > button {
    padding: 0 1px 0 0;
  }

  & > div > div > button > svg {
    width: 18px;
  }
}


.spinnerContainer {
  position: absolute;
  z-index: 20;
  left: calc(50% - 10px);
  bottom: 10px;
}


.sortMirrorVertical {
  transform: scale(1, -1);
}

.sortValue {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 5px;
}