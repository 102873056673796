@import "variables";

.root {}

body {
  background: $bgBlue;
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

h1,h2,h3,h4,h5,h6 {
  margin: 0;
  padding: 0;
}

