@import "../../styles/variables";

.root {
  width: 100%;
  height: 100%;
  font-size: 14px;
}

.tableBar {
  display: flex;
  height: 40px;
  align-items: end;
  justify-content: space-between;
  padding: 0 5px 5px 5px;
  background: $bgBlue;
}

.tableName {
  font-size: 15px;
  font-weight: 600;
  line-height: normal;
}

.tableBtn {
  background: $primaryGreen;
  width: 80px;
  height: 25px;
  padding: 0 10px 0 5px;
  border-radius: 5px;
  color: white;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.tableBtnDisabled {
  pointer-events: none;
  cursor: none;
  opacity: 0.4;
}

.footer {
  height: 20px;
  width: 100%;
  background: $bgBlue;
}