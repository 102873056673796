@import "../../../styles/variables";

.root {
  background: $mainBlue;
  width: 95%;
  display: flex;
  align-items: start;
  grid-gap: 10px;
  margin-top: 10px;
}

.rootUser {
  flex-direction: row-reverse;
}

.avatar {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  background: $primaryPurple;
  color: white;
}

.avatarUser {
  background: white !important;
  color: $primaryPurple !important;
}


.message {
  margin-top: 10px;
  padding: 10px 10px 5px 10px;
  max-width: 60%;
  min-width: 250px;
  overflow-wrap: break-word;
}

.messageFrom {
  color: $textColorSecondary;
  background: white;
  border-radius: 0 8px 8px 8px;
}

.userMessage {
  color: white;
  background: $primaryPurple;
  border-radius: 8px 0 8px 8px;

}

.userName {
  color: #5494DA;
}

.statusBar {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 10px;
  align-items: center;
}

.statusText {
  margin-left: 5px;
}

.statusIconRead {
}