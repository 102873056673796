.selectError .select__placeholder {
    color: #FC5A5A;
}

.selectError .select__control  {
    border: 1px solid #FC5A5A !important;
}


.deal-data-picker label {
    width: fit-content;
    line-height: normal;
    top: -3px;
    font-size: 12px;;
}

.deal-data-picker label.Mui-focused {
    top: 2px;
    font-size: 14px !important;
}

.deal-data-picker label.MuiFormLabel-filled {
    top: 2px;
    font-size: 14px !important;
}

.deal-data-picker .MuiSvgIcon-root  {
    font-size: 20px !important;
}