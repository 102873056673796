@import "../../styles/variables";

$statusBorderWidth: 3px;

.root {
  padding: 10px 10px 0px 10px;
  border-top: 5px solid $bgBlue;

  &:first-child {
    border-top: 0;
  }

}

.active {
  background: $bgBlue;
}

@keyframes blinkingText{
  0%		{ background: white;}
  50%		{ background: #C6D9FF;}
  100%	    {
    background: white;
  }
}


.new {
  //background: $blue;
  //color: white;
  animation: blinkingText 4s linear;

/*  & .date {
    color: white;
  }

  & .key {
    color: rgba(255, 255, 255, 0.8 );
  }

  & .value {
    color: white;
  }

  & .status {
    color: white;
  }

  & .statusIcon {
    border: $statusBorderWidth solid white !important;
  }*/
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
  border-bottom: 1px solid $lightGray;
}


.date {
  color: $textColor;
  font-size: 12px;
  padding-bottom: 1px;
}


.status {
  color: black;
  display: flex;
  align-items: center;
  font-size: 15px;
}


.statusUnknown {
  color: black;

  & .statusIcon {
    border: $statusBorderWidth solid black;
  }
}

.quotesProposed {
  color: $primaryPurple;

  & .statusIcon {
    border: $statusBorderWidth solid $primaryPurple;
  }
}

.statusNew {
  color: $primaryGreen;

  & .statusIcon {
    border: $statusBorderWidth solid $primaryGreen;
  }
}

.statusSendInInsuranceCompany {
  color: $orange;

  & .statusIcon {
    border: $statusBorderWidth solid $orange;
  }
}

.statusInWork {
  color: $blue;

  & .statusIcon {
    border: $statusBorderWidth solid $blue;
  }
}

.statusIcon {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-right: 5px;
}


.info {
  padding-top: 10px;
  padding-right: 15px;
  cursor: pointer;
}

.keyValue {
  display: flex;
  align-items: start;
}

.key {
  font-size: 12px;
  color: $textColor;
  margin-right: 3px;
}

.value {
  font-size: 14px;
  color: $textColorSecondary;
}

.footer {
  margin-top: 20px;
  height: 40px;
  border-top: 1px solid $lightGray;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footerRightButtons {
  display: flex;
  grid-gap: 10px;
}


.actionInProcessBtn {
  opacity: 0.6;
  pointer-events: none;
}

.btn {
  border-radius: 5px;
  background: $primaryGreen;
  color: white;
  font-size: 12px;
  cursor: pointer;
  height: 26px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.badBtn {
  border-radius: 5px;
  background: $red;
  color: white;
  font-size: 12px;
  cursor: pointer;
  height: 26px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}