@import "../../../styles/variables";

.root {
  height: 100%;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  line-height: normal;
  text-decoration: none;
  color: $linkColor;
  font-weight: 400;

  &:hover {
    cursor: pointer;

    color: $blue;

    & > .icon {
      color: $blue;
    }
  }

}

.rootActive {
  color: $blue;

  & > .icon {
    color: $blue;
  }
}

.icon {
  padding-right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    width: 20px;
  }
}
