@import "../../styles/variables";


.root {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

}

.error {
  color: $red;
  text-align: center;
  margin-top: 10px;
}

.clientForm {
  width: 70%;
  height: 80%;
}


.chooseRoot {

  width: 400px;
  background: white;

}

.chooseHeader {
  background: $lightGray;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chooseContent {
  padding: 20px 40px;
}

.chooseBtnContainer {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  border: 1px solid $placeholderColor;
  border-radius: 5px;
}


.chooseBtnActive {
  background: $blue;
  color: white;
}

.chooseBtn {
  padding: 5px 10px;
  white-space: nowrap;
  border-radius: 5px;
  cursor: pointer;
}

.chooseInnText {
  border-left: 1px solid #FF974A;
  padding-left: 5px;
  color: $textColor;
  margin-top: 30px;
  margin-bottom: 30px;
}

.chooseInnField {
  width: 100%;
}

.chooseSubmitContainer {
  display: flex;
  justify-content: center;
  grid-gap: 40px;
  margin-top: 50px;
  align-items: center;
}



.chooseCancel {
  color: $red;
  cursor: pointer;
}

.chooseSubmitDisabled {
  pointer-events: none;
}

.chooseSubmit {
  background: $blue;
  color: white;
  padding: 8px 20px;
  white-space: nowrap;
  border-radius: 5px;
  cursor: pointer;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.closeIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.chooseOrgModal {
  z-index: 110;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  height: 400px;
  width: 600px;
  padding: 20px;
  position: relative;
}

.orgList {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  height: 80%;
  overflow-y: auto;
  width: 100%;
  @include scrollStyle
}

.orgItem {
  background: $bgBlue;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background: $blue;
    color: white;
  }
}
